import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

const Head = ({ pageTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `
  )

  const { title, author, description } = data.site.siteMetadata

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="author" content={author} />
      <meta name="description" content={description} />
      <title>{`${pageTitle} | ${title}`} </title>
      <html lang="en" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  )
}

export default Head
