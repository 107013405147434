import React, { useState, useEffect } from "react"
import posed from "react-pose"
import { TransitionState } from "gatsby-plugin-transition-link"

import Header from "../components/Header/Header"
import BackToTopBtn from "./../components/BackToTopBtn/BackToTopBtn"
import "bootstrap/dist/css/bootstrap.min.css"
import "./BaseLayout.css"

let goToTop

const Box = posed.div({
  hidden: { height: 0 },
  visible: { height: "100vh" },
})

const BaseLayout = ({ showCloseIcon = false, children }) => {
  const [position, setPosition] = useState(0)

  useEffect(() => {
    let elm = document.querySelector(".container-fluid.main")
    const handleScroll = function(event) {
      setPosition(this.scrollTop)
    }
    elm.addEventListener("scroll", handleScroll)
    goToTop = () =>
      (document.querySelector(".container-fluid.main").scrollTop = 0)
    return function() {
      elm.removeEventListener("scroll", handleScroll)
    }
  }, [position])

  return (
    <TransitionState>
      {({ mount, transitionStatus }) => {
        return (
          <Box className="box" pose={mount ? "visible" : "hidden"}>
            <div
              className="container-fluid main"
              style={{
                fontFamily: "Helvetica neue",
                backgroundColor: "white",
                position: "relative",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <Header showCloseIcon={showCloseIcon} position={position} />

              {children}
              {position > 200 ? <BackToTopBtn toTop={goToTop} /> : null}
            </div>
          </Box>
        )
      }}
    </TransitionState>
  )
}

export default BaseLayout
