import React from "react"
import { MdArrowUpward } from "react-icons/md"
import Colors from "./../../Theme/theme"

const BackToTopBtn = props => {
  return (
    <div
      style={{ color: Colors.accent }}
      onClick={() => props.toTop()}
      role="button"
      tabIndex="0"
    >
      <MdArrowUpward
        size={50}
        style={{
          position: "fixed",
          top: "85%",
          right: "5%",
          color: "white",
          backgroundColor: Colors.accent,
          border: `1px solid ${Colors.accent}`,
          borderRadius: "50%",
          zIndex: "99999999",
          cursor: "pointer",
          padding: "10px",
        }}
      />
    </div>
  )
}

export default BackToTopBtn
