import React from "react"
// import { Link } from "gatsby"
import { MdClose } from "react-icons/md"
import styles from "./Header.module.css"
import Colors from "./../../Theme/theme"
import { navigate } from "@reach/router"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import TransitionLink from "gatsby-plugin-transition-link"

const linksArr = [
  { name: "Home", path: "/" },
  // { name: "Projects", path: "/projects" },
  { name: "Blog", path: "/blog" },
]

const Header = ({ showCloseIcon, position }) => {
  const allIcons = (
    <div className="mr-md-5 pr-md-5 mt-3 mt-md-0">
      {linksArr.map(link => (
        <TransitionLink
          key={link.path}
          to={link.path}
          className={`mx-4 pl-md-5 mx-md-0 ${styles.link}`}
          style={{ color: position > 50 && "#fff" }}
          activeStyle={{ color: Colors.primary }}
          exit={{ length: 0.3 }}
          entry={{ delay: 0.5 }}
        >
          {link.name}
        </TransitionLink>
      ))}
    </div>
  )

  const closeIcon = (
    <div className="mr-md-5 pr-md-5 mt-3 mt-md-0 align-self-end">
      <div className={`mx-5 mx-md-4 pl-md-5 mx-md-0 ${styles.link}`}>
        <MdClose
          onClick={() => navigate("/#get-in-touch-btn-div")}
          size={40}
          style={{ color: position > 50 && "#fff" }}
        />
      </div>
    </div>
  )

  return (
    <header
      className={`${styles.myHeader} row py-3 pt-sm-4`}
      style={{
        boxShadow: position < 50 && "none",
        backgroundColor: position > 50 && "#b6b6b6",
      }}
    >
      <nav className="d-flex flex-column justify-content-center flex-md-row flex-md-wrap justify-content-md-between align-items-center col-12 px-0">
        <div
          className={`${showCloseIcon &&
            "d-none d-md-block"} ml-md-5 pl-md-5 text-center text-md-left`}
        >
          <TransitionLink to="/" hex={Colors.primary} bg={Colors.primary}>
            <img
              src={
                position < 50
                  ? require("./../../assets/my-logo-black.png")
                  : require("./../../assets/my-logo-white.png")
              }
              alt="my logo"
              className="px-0 mx-0"
              style={{ width: "40%" }}
            />
          </TransitionLink>
        </div>
        {showCloseIcon ? closeIcon : allIcons}
      </nav>
      <hr style={{ width: position < 50 ? "90%" : 0 }} />
    </header>
  )
}

export default Header
